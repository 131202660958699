.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    div {
        letter-spacing: 2px;
        width: 218px;
        font-style: italic;
        transform: rotate(-5deg);
        text-align: right;
    }
}


.disp {
    button {
        display: block;
        border: 1px solid #4cb24257;
    }
    :after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -3px;
        height: 2px;
        background: #4cb242;;
    }
    .name, .role {
        text-transform: none;
        color: black;
        text-align: right;
        font-weight: 300;
    }
    .name {
        font-size: 16px;

    }
    .role {
        font-size: 12px;
    }
}

.appName {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 2px;
    width: 218px;
    font-style: italic;
    transform: rotate(-5deg);
    text-align: right;
}

.neonGreen {
    text-shadow: 2px 2px 22px #ffffff;
}