
$green: #39FF14;


.finBlock2 {
    margin: 25px 15px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    >div {
        text-align: center;
        padding: 10px;
        position: relative;
        overflow: hidden;
        box-shadow: -3px 3px 1px $green, 3px -3px 1px #4dab3a;
        border-radius: 10px;
        cursor: pointer;
        width: 15%;
        min-width: 170px;
        transition: box-shadow, color 0.3s;
        &:hover {
            box-shadow: 3px 3px 1px #4dab3a, -3px -3px 1px $green;
            color: #8181815c;
            svg {
                color: rgb(156, 156, 156);
                left: 30%;
            }
        }
        :first-child {
            margin-bottom: 10px;
        }
        :last-child {
            font-size: 24px;
        }
    }
    svg {
        transition: color, left 0.5s;
        position: absolute;
        top: -4px;
        left: 7px;
        font-size: 78px;
        color: #8181815c;
    }
}


