header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}
.lion {
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 34%;
    position: fixed;
    z-index: 0;
    right: 0%;
    width: 105%;
    height: 100%;
}
main .pageWrapper {
    display: flex;
    justify-content: center;
    padding-top: 65px;
    margin-left: 60px;
}


.sideMenu {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    background: #F5F5F5;
    -webkit-transition: width .1s linear;
    transition: width .1s linear;
    -webkit-transform: translateZ(0) scale(1, 1);
    z-index: 999;
    position: fixed;
    top: 64px;
    bottom: 0;
    height: calc(100% - 64px);
    left: 0;
    width: 64px;
    text-wrap: nowrap;

    &:hover {
        width: 230px;
        overflow: visible;
    }

    .menuIcon {
        width: 24px;
        margin-right: 20px;
    }
    .menuIcon.themeColor {
        filter: brightness(0);
    }
    a {
        text-decoration: none;
        color: inherit;
    }
    .active .MuiTreeItem-content{
        background-color: #ffffff24;
        border-left: 3px solid green;
    }
    .MuiTreeItem-content {
        padding: 4px 0px;
        border-radius: 0;
        gap: 0;
        &:hover, &:focus {
            background-color: #ffffff24;
            border-left: 3px solid green;
            &:after {
                content: "";
                transform: rotate(135deg);
                border-right: 2px solid currentcolor;
                border-bottom: 2px solid currentcolor;
                width: 4px;
                height: 4px;
            }
            img, .img, .ver {
                animation: swing ease-in-out 0.5s 1 alternate;
            }
        }
    }
    .MuiCollapse-root {
        padding-left: 5px;
    }
    .MuiCollapse-wrapper {
        border-left: 1px dashed #ffffff4d;
    }
    .MuiListItemButton-root {
        padding-left: 4px;
        &:hover {
            background-color: inherit !important;
        }
    }
    .MuiListItemIcon-root {
        min-width: 44px;
    }
    .active {
        background-color: rgb(189 189 189 / 38%) !important;
        transition: color .5s ease-in-out;
        font-weight: 600;
        /*text-shadow: 1px 1px black;*/
        div {
            color: #4bb141;
            /*text-shadow: 1px 1px 1px black;*/
        }
    }
    /*.active .themeColor{
        background-color: rgb(76, 178, 66);
    }*/


}

@keyframes swing {
    0%,
    30%,
    50%,
    70%,
    100% {
        transform: rotate(0deg);
    }

    10% {
        transform: rotate(10deg);
    }

    40% {
        transform: rotate(-10deg);
    }

    60% {
        transform: rotate(5deg);
    }

    80% {
        transform: rotate(-5deg);
    }
}



.pageContainer {
    flex: 1 0 auto;
    height: calc(100vh - 65px);
    max-width: 99%;
    z-index: 100;
    .main {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 65px);
        margin: 0 auto;
        padding: 0 30px;
        .topTitle {
            display: flex;
            justify-content: space-between;
            padding-top: 15px;
        }
    }
}



/*Black BG with stars*/
.stars, .twinkling {
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width:100%;
    height:100%;
    display:block;

}

.stars {
    /*background:#000 url(https://i.postimg.cc/hhFD7Nkf/apps-47636-13819498107583324-fb06b53a-4974-4acb-85e6-f6f363f85ab6-edec48d0-ec35-4a1f-8bf5-c455987b4a.png) repeat top center;*/
    background:#000 url('../img/darkTheme/stars.png') repeat top center;
    z-index: 0;
}

.twinkling {
    /*background-image: url(https://i.postimg.cc/CLpNr4PF/twinkling.png);*/
    background-image: url('../img/darkTheme/twinkling.png');
    animation: 3s twinkle infinite;
    animation-timing-function: linear;
}

@keyframes twinkle {
    from {backrgound-position: 0}
    to {background-position: -100px 0}
}
