.chartTooltipTitle {
    text-shadow: 0px 1px black;
    .head {
        text-align: center;
        border-bottom: 1px solid;
        padding-bottom: 4px;
    }
    span {
        float: right;
        border-bottom: .1px dashed;
    }
}

/*barchart  xaxis click style*/
.xaxis-label {
    transition: color 0.3s;
}
.xaxis-label:hover {
    color: red; /* Change color on hover */
}

/*slider*/
.center {
    .slick-next:before,
    .slick-prev:before {
        /*color: #2db432 !important;*/
        color: #b1b1b1  !important;
    }
}


.procFilters {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .searchFilter {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        .objects {
            padding-bottom: 5px;
        }
    }
    .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon, .css-1mf6u8l-MuiSvgIcon-root-MuiSelect-icon {
        fill: rgb(255 255 255 / 54%);
    }
}


.css-11xzqlw-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    background-color: #5c5c5c !important;
}
.css-11xzqlw-MuiButtonBase-root-MuiSwitch-switchBase {
    color: #9c9c9c !important;
}
.css-1yjjitx-MuiSwitch-track {
    background-color: #5c5c5c !important;
}