.block {
  display: flex;
  justify-content: center;
  text-align: center;
}
h1 {
  font-size: 4.2rem;
    margin-bottom: 70px ;
}

h2 {
  font-size: 1.8rem;
}

.neonText {
  color: #fff;
  text-shadow:
          0 0 7px #fff,
          0 0 10px #fff,
          0 0 21px #fff,
          0 0 42px #0fa,
          0 0 82px #0fa,
          0 0 92px #0fa,
          0 0 102px #0fa,
          0 0 151px #0fa;
}
.flicker { animation: flicker 1.5s infinite alternate;}
@keyframes flicker {
    0%, 18%, 22%, 25%, 53%, 57%, 100% {
        text-shadow:
                0 0 4px #fff,
                0 0 11px #fff,
                0 0 19px #fff,
                0 0 40px #0fa,
                0 0 80px #0fa,
                0 0 90px #0fa,
                0 0 100px #0fa,
                0 0 150px #0fa;
    }
    20%, 24%, 55% {
        text-shadow: none;
    }
}

@keyframes pulsate {

    100% {

        text-shadow: 0 0 4px #fff,
        0 0 11px #fff,
        0 0 19px #fff,
        0 0 40px #0fa,
        0 0 80px #0fa,
        0 0 90px #0fa,
        0 0 100px #0fa,
        0 0 150px #0fa;

    }

    0% {

        text-shadow: 0 0 2px #fff,
        0 0 4px #fff,
        0 0 6px #fff,
        0 0 10px #0fa,
        0 0 45px #0fa,
        0 0 55px #0fa,
        0 0 70px #0fa,
        0 0 80px #0fa;

    }
}