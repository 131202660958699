.wikiBlock {
    .wikiTitle {
        font-style: italic;
        font-weight: 500;
        text-shadow: 2px 2px 22px #ffffff;
        text-align: center;
    }
    p {
        text-indent: 30px;
        text-align: justify;
    }
}
