.econMain {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    gap: 20px;
    .ecoBlocks {
        flex-basis: 170px;
        padding-top: 15px;
        .ecoDataDate {
            padding: 15px 10px;
            color: #0a8016;
            font-weight: 600;
            border: 1px solid #818181;
            border-radius: 10px;
            margin-bottom: 10px;
            cursor: pointer;
            transition: box-shadow 0.3s;
            &:hover {
                box-shadow: 0 0 15px #818181;
                svg{
                    animation:spin 8s linear infinite;
                }
            }
            .ecoSettings {
                display: flex;
                justify-content: space-between;
                align-items: center;
                /*padding: 5px 0;*/
                font-size: 18px;
                .ecoTitle {
                    color: grey;
                    font-size: 14px;
                }
            }
            @keyframes spin {
                100% {
                    transform:rotate(360deg);
                }
            }
        }
        .ecoDataNum {
            margin-bottom: 10px;
            padding: 10px;
            border: 1px solid;
            border-radius: 10px;
            font-size: 13px;
            cursor: default;
            transition: box-shadow 0.3s;
            $green: #39FF14;
            $orange: #ffa420;
            $red: #FF0000;
            $yellow: #FFFF00;
            $lblue: #00FFE1;

            $greenL: #61CE95;
            $orangeL: #F7A25C;
            $redL: #EF7065;
            $yellowL: #d0c900;
            $lblueL: #79C3E2;

            &.ecoGreen {
                &.D {
                    border-color: $green;
                    &:hover {box-shadow: 0px 0px 15px $green; }
                }
                &.L {
                    border-color: $greenL;
                    &:hover {box-shadow: 0px 0px 15px $greenL; }
                }
            }
            &.ecoOrange {
                &.D {
                    border-color: $orange;
                    &:hover {box-shadow: 0px 0px 15px $orange;}
                }
                &.L {
                    border-color: $orangeL;
                    &:hover {box-shadow: 0px 0px 15px $orangeL;}
                }
            }
            &.ecoRed {
                &.D {
                    border-color: $red;
                    &:hover {box-shadow: 0px 0px 15px $red;}
                }
                &.L {
                    border-color: $redL;
                    &:hover {box-shadow: 0px 0px 15px $redL;}
                }
            }
            &.ecoYellow {
                &.D {
                    border-color: $yellow;
                    &:hover {box-shadow: 0px 0px 15px $yellow;}
                }
                &.L {
                    border-color: $yellowL;
                    &:hover {box-shadow: 0px 0px 15px $yellowL;}
                }
            }
            &.ecoLblue {
                &.D {
                    border-color: $lblue;
                    &:hover {box-shadow: 0px 0px 15px $lblue;}
                }
                &.L {
                    border-color: $lblueL;
                    &:hover {box-shadow: 0px 0px 15px $lblueL;}
                }
            }
            .ecoDataTitle {
                text-align: center;
                margin-bottom: 10px;
                font-size: 15px;
            }
            span {
                float: right;
            }
            $mainGreenD: #39FF14;
            $mainGreenL: #4bb141;
            .ecoDataCurrentMonth {
                font-weight: 600;
                &.D {
                    color: $mainGreenD;
                }
                &.L {
                    color: $mainGreenL;
                }
            }

        }
    }
    .ecoBlocks2 {
        width: calc(100% - 170px);
    }
}

.ecoDetails {
    margin-top: 15px;
    .month {
        text-align: center;
        font-size: 50px;
        margin-bottom: 25px;
        font-style: italic;
    }
    .header{
        display: flex;
        align-items: flex-start;
        height: 100%;
        &.dark {
            color: #e4e4e4;
        }
        &.light {
            color: #717272;
        }
    }

    .headBlock {
        width: 22%;
    }
    .headBlockItem {
        padding: 7px;
        text-align: center;
        &.light {
            &.z{background-color: #CAEDFC; }
            &.v{background-color: #FBD5D1; }
            &.o{background-color: #C0F3DA; }
            &.n{background-color: #f3aca6; }
        }

    }
    .headBG{
        margin-bottom: 0px;
        &.dark {
            background-color: #171717;
            color: #a7a7a7;
        }
        &.light {
            background-color: #848fa117;
            color: #7f7f7f;
        }
    }
    .lineBG{border: 1px solid #80808045;}
    .fb33 {flex-basis: 33%;}
    .fb49 {flex-basis: 49%;}
    .flexYear {
        width: 10%;
        text-align: center;
    }
}



.flexItem {
    display: flex;
    justify-content: space-around;
    .flexL {
        border-right: 1px solid #5c5c5c;
        width: 49%;
    }
    .flexR {width: 49%;}
    .pointL {font-size: .9rem;}
    .pointR {font-weight: 600;}
}


/*barchart  xaxis click style*/
.xaxis-label {
    transition: color 0.3s;
}
.xaxis-label:hover {
    color: red; /* Change color on hover */
}


.amg {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    .amgChart {
        &.funnel {
            flex-basis: 31%;
        }
        &.double {
            flex-basis: 31%;
        }
        &.bullet {
            flex-basis: 35%;
        }

        .chartTitle {
            padding-top: 7px;
            text-align: center;
            margin: 3px 0px;
            font-size: 14px;
            color: #a7a7a7;
            font-weight: 600;
            position: relative;
        }
    }
}

.textTrans {
    text-transform: capitalize;
    line-height: 14px;
}