.goalTitles {
    div{
        padding-bottom: 5px;
        span {
            color: grey;
            padding-right: 10px;
        }
    }

}





.headerGoal {
    padding: 5px;
    gap: 5px;
    display: flex;
    flex-wrap: nowrap;
    border: 1px solid rgb(57, 255, 20);
    margin: 5px;
    border-radius: 3px;
    font-size: 13px;
    justify-content: space-between;
}

.gtitle {
    padding: 2px;
    font-size: 14px;
    font-weight: 600;
    &.red{
        margin-left: 15px;
    }
}
.mAuto { margin: auto 0}

.blue {
    @extend .mAuto;
    color: #7bc4fe;
}
.red{
    @extend .mAuto;
    color: #fd634e;
}
.green{
    @extend .mAuto;
    color: #45a822;
}
.orange{
    @extend .mAuto;
    color: #e4bc56;
}

.tac{
    text-align: center;
    margin: auto;
    font-size: 13px;
    &.fs13 {
        div {
            font-size: 13px;
        }
    }
}
.pad0 {
    padding: 0 !important;
}


.goalCard {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    min-height: 16px;
    width: 30%;
    font-size: 14px;
    min-width: 370px;
    border: 1px solid rgb(142 142 142 / 38%);
    margin-bottom: 15px;
    margin-right: 15px;
    &:hover {
        background-color: #3fb32975;
    }
    .goalText {
        padding: 15px;
        span {
            font-size: 12px;
            color: grey;
        }

    }

    .goalRole {
        width: 80px;
        height: 30px;
        margin-bottom: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        &.rp {
            background-color: #66a1d0;
        }
        &.rs {
            background-color: #66cc4f;
        }
        &.gip {
            background-color: #9c9c9c;
            color: grey;
        }
    }
}

