.tableHeader {
    padding: 10px;
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    div {
        font-size: 15px;
        word-wrap: break-word;
    }
    .listIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        svg {
            font-size: 1.1rem;
            padding-right: 5px;
        }
    }
    .control {
        cursor: pointer;
    }
}

.tableItem{
    padding: 10px;
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    min-height: 16px;
    font-size: 14px;
    border: 1px solid rgb(142 142 142 / 18%);
    /*:nth-child(n+3) {
        text-align: center;
    }*/
    &:hover {
        /*background-color: #3fb329;*/
        background-color: #3fb32975;
        /*color: black;*/
        .edit {
            display: block;
        }
    }
    .edit {
        position: absolute;
        right: 2px;
        bottom: 3px;
        display: none;
        .MuiButtonBase-root {min-width: 40px !important;}
    }
    .status {
        position: absolute;
        bottom: -8px;
        left: 10px;
    }
}

.transport {
    width: fit-content;
    background: white;
    color: black;
    padding: .2rem;
    margin: 0 auto;
    font-weight: 600;
}
.flexCenter {
    justify-content: center;
}

.grey {
    color: grey;
}