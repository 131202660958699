.authBG{
  /*background-image: url('../../img/auth/authBG.png');*/
  background-color: rgb(255,255,255);
}
.logoBG{
  background-image: url('../../img/auth/logoLight.png');
  background-repeat: no-repeat;
  background-size: 26%;
  background-position: 5% 5%;
}

.lionBG {
  /*background-image: url('../../img/auth/Lion.png');*/
  background-image: url('../../img/bg_lionRight2.png');
  background-repeat: no-repeat;
  background-position: 119% 45%;
  background-size: 36%;
}

.loginContainer {
  font-family: DINCyr,Arial,sans-serif!important;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .box {
    background-color: #fff;
    border-left: 10px solid rgb(86 190 6);
    border-right: 10px solid rgb(86 190 6);
    border-radius: 30px;
    box-shadow: 0 4px 17px rgba(0,0,0,.17);
    width: 350px;

    position: fixed;
    >div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px;
      color: rgb(86 190 6);
      .img{
        width: 80%;
        margin: 0 auto;
        padding-top: 15px;
      }
    }


  }
}


.blink {
  animation: blink-animation 1s steps(10, start) infinite;
  -webkit-animation: blink-animation 1s steps(10, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}