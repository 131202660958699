.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*width: 600px;*/
  width: 60%;
  padding: 14px 32px;
  outline: none;
  border-radius: 25px 25px 25px 25px;
  border-left: .4rem solid #4cb242;
  max-height: 90%;
}

.modalBtn {
  position: absolute;
  right: -37px;
  top: -37px;
  button {
    color: white;
  }
}

.modalBody2{
  font-size: 0.875rem;
}

code {
  color: grey;
  display: block;
  border-radius: 12px;
  padding: 10px;
  border: 1px solid;
  border-color: #E4E4E4;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.MuiChip-label {
  color: white;
}


.tred {
  color: #f60209;
}
.tyellow {
  color: #EBDA09
}

.modalAuthTitle{
  font-style: italic;
  font-weight: 500;
  text-shadow: 2px 2px 22px #ffffff;
  text-align: center;
}
.modalIcon {
  position: absolute;
  left: -21px;
  top: 45%;
  color: rgb(255, 255, 255);
  border: 4px solid #4cb242;
  border-radius: 50%;
  background: #4cb242;
}

.realization{
  .blockItem {
    padding: 7px;
    border-radius: 5px;
    &.dark {
      &.greyShadow {
        box-shadow: -1px 1px 10px #8a8a8a, 0px -2px 10px #8a8a8a;
      }
      &.greenShadow {
        box-shadow: -1px 1px 10px #1dd530, 0px -2px 10px #1dd530;
      }
      &.blueShadow {
        box-shadow: -1px 1px 10px #0095b6, 0px -2px 10px #0095b6;
      }
    }
    &.light {
      &.greyShadow {
        background-color: #6161611f;
      }
      &.greenShadow {
        background-color: #61ce9552;
      }
      &.blueShadow {

      }
    }

    .formula {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-size: 1rem;
    }
    .formula2 {
      display: flex;
      justify-content: space-evenly;
      gap: 10px;
      text-align: center;
    }
    .MuiChip-label {
      color: black;
      white-space: break-spaces;
    }
  }
}


.ecoModal {
  .divider {
    border-bottom: thin solid grey;
    margin: 15px 0;
    position: relative;
    .title {
      position: absolute;
      top: -15px;
      background: #808080bd;
      padding: 5px;
      border-radius: 5px;
    }
  }
}