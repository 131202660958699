$green: #39FF14;

.miniHRblocks {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 25px 15px;
    >div:not(.settings) {
        text-align: center;
        padding: 10px;
        margin-bottom: 15px;
        /*border: 1px solid $green;*/
        position: relative;
        overflow: hidden;
        box-shadow: -3px 3px 1px $green, 3px -3px 1px #4dab3a;
        border-radius: 10px;
        cursor: pointer;
        width: 10%;
        min-width: 170px;
        transition: box-shadow 0.3s;
        &:hover {
            box-shadow: 3px 3px 1px #4dab3a, -3px -3px 1px $green;
            color: #8181815c;
            svg {
                color: rgb(156, 156, 156);
                left: 30%;
            }
        }
        :first-child {
            margin-bottom: 10px;
        }
        :last-child {
            font-size: 24px;
        }
    }
    svg {
        position: absolute;
        transition: color, left 0.5s;
        top: -4px;
        left: 7px;
        font-size: 78px;
        color: #8181815c;
    }
}

.chartHRName {
    text-align: center;
    font-size: 20px;
}


.chartInTable {
    display: flex;
    align-items: center;
}

.emplBlock {
    display: flex;
    //flex-wrap: nowrap;
    align-items: flex-start;
    gap: 20px;
    padding: 0px 5px;
    .empl1 {
        //flex-basis: 170px;
        width: 170px;
        border: 1px solid #818181;
        border-radius: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        transition: box-shadow 0.3s;
        position: relative;
        &:hover {
            box-shadow: 0 0 15px #818181;
        }
        .settings {
            animation:spin 8s linear infinite;
        }
        @keyframes spin {
            100% {
                transform:rotate(360deg);
            }
        }
    }
    .empl2 {
        //width: calc(100% - 170px);
    }
}

.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon, .css-1mf6u8l-MuiSvgIcon-root-MuiSelect-icon {
    fill: rgb(255 255 255 / 54%) !important;
}